import Cookies from 'js-cookie';
import merge from 'lodash/merge';
import { tokenService } from '../services/token.service';
import { AuthError, isSSORequestUrl } from '../services/auth.service';

/**
 * The auth module mixin
 *
 * @param {*} config    Configuration for the auth module
 * @property {VueRouter} router           reference to a VueRouter instance
 * @property {AuthService} authService    reference to a AuthService instance
 * @property {string|object} defaultRoute defines the route the default point the router needs to
 *                                        return to after an user session has ended
 */
export const authModule = ({ router, authService, defaultRoute = '/' }) => module => merge({
  state: {
    authenticating: false,
    accessToken: tokenService.getToken(),
    ssoLoginFlow: false,
    authErrorCode: 0,
    authError: '',
  },

  getters: {
    loggedIn(state) {
      return !!state.accessToken;
    },

    isSSOLoginFlow(state) {
      return state.ssoLoginFlow;
    },

    authErrorCode(state) {
      return state.authErrorCode;
    },

    authError(state) {
      return state.authError;
    },

    authInProgress(state) {
      return state.authenticating;
    },
  },

  actions: {
    async login({ commit }, {
      email, password, otpCode = null, tmpToken = null, scopes = [], redirect = true, loginRequestUrl = '',
    }) {
      commit('loginRequest');

      try {
        let response;
        let ssoAssertion;
        const isSSORequest = isSSORequestUrl(loginRequestUrl);

        if (otpCode) {
          response = await authService.loginTwoFactor(email, tmpToken, otpCode, scopes);
        } else {
          response = await authService.login(email, password, scopes);
        }

        const { token, requiresSecondaryAuthentication } = response;

        if (!requiresSecondaryAuthentication) {
          if (isSSORequest) {
            ssoAssertion = await authService.generateSSOAssertion(loginRequestUrl);
          }

          commit('loginSuccess', { accessToken: token, isSSOLoginFlow: isSSORequest });

          if (redirect) {
            router.push(router.history.current.query.redirect || defaultRoute);
          }
        }

        return {
          success: true,
          data: { token, requiresSecondaryAuthentication, ssoAssertion },
          error: null,
        };
      } catch (err) {
        if (err instanceof AuthError) {
          commit('loginError', err);
        }

        return { success: false, data: null, error: err };
      }
    },

    async requestOTP(state, { email }) {
      try {
        const data = await authService.requestOTP(email);

        return { success: true, data, error: null };
      } catch (err) {
        return { success: false, data: null, error: err };
      }
    },

    async requestSSOAssertionForLoggedIn(state, { ssoRequestUrl }) {
      try {
        return await authService.generateSSOAssertion(ssoRequestUrl);
      } catch (err) {
        console.error('Error in requestSSOAssertionForLoggedIn:', err);
        return null;
      }
    },

    logout({ commit }) {
      authService.logout();
      Cookies.remove('token');
      commit('logout');
      router.push(defaultRoute).catch(() => {
        window.location.reload();
      });
    },
  },

  mutations: {
    loginRequest(state) {
      state.authenticating = true;
      state.authError = '';
      state.authErrorCode = 0;
    },

    loginSuccess(state, { accessToken, isSSOLoginFlow }) {
      state.accessToken = accessToken;
      state.ssoLoginFlow = isSSOLoginFlow;
      state.authenticating = false;
    },

    loginError(state, { message, errorCode }) {
      state.authenticating = false;
      state.authError = message;
      state.authErrorCode = errorCode;
    },

    logout(state) {
      state.accessToken = '';
    },
  },
}, module);
